// .container {
//   display: flex;
//   align-items: center;
// }

// .load21 {
//   position: relative;
//   content: "";
//   height: 0.2em;
//   width: 4em;
//   border-radius: 5px;
//   opacity: 0;
//   background-color: white;
//   box-shadow: 0 1px 7px rgba(0,0,0,0.3);
//   animation: 2s flow_21 infinite;
//   animation-delay: 0.2s;
// }

// .load22 {
//   position: absolute;
//   content: "";
//   height: 0.2em;
//   width: 4em;
//   opacity: 0;
//   border-radius: 5px;
//   background-color: white;
//   animation: 2s flow_21 infinite;
//   animation-delay: 0.4s;
// }

// .load23 {
//   position: absolute;
//   content: "";
//   height: 0.2em;
//   width: 4em;
//   opacity: 0;
//   border-radius: 5px;
//   background-color: white;
//   animation: 2s flow_21 infinite;
//   animation-delay: 0.6s;
// }

// .load24 {
//   position: absolute;
//   content: "";
//   height: 0.2em;
//   width: 4em;
//   opacity: 0;
//   border-radius: 5px;
//   background-color: white;
//   animation: 2s flow_21 infinite;
//   animation-delay: 0.8s;
// }

// .load25 {
//   position: absolute;
//   content: "";
//   height: 0.2em;
//   width: 4em;
//   opacity: 0;
//   border-radius: 5px;
//   background-color: white;
//   animation: 2s flow_21 infinite;
//   animation-delay: 1s;
// }

// @keyframes flow_21 {
//   0% {
//     transform: translateY(1em);
//     opacity: 1;
//   }

//   50% {
//     transform: translateY(-1em);
//     opacity: 1;
//   }

//   100% {
//     transform: translateY(1em);
//     opacity: 1;
//   }
// }

//-------------------------------------

// .loader {
//   position: relative;
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   background: linear-gradient(#ee280e, #15a0f7, #6ed15a);
//   animation: animate7712 1.2s linear infinite;
// }

// @keyframes animate7712 {
//   0% {
//     transform: rotate(0deg);
//   }

//   100% {
//     transform: rotate(360deg);
//   }
// }

// .loader span {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   background: linear-gradient(#ee280e, #15a0f7, #5ad15a);
// }

// .loader:after {
//   content: '';
//   position: absolute;
//   top: 10px;
//   left: 10px;
//   right: 10px;
//   bottom: 10px;
//   background: #333;
//   border: solid #333 10px;
//   border-radius: 50%;
// }

// .loader span:nth-child(1) {
//   filter: blur(5px);
// }

// .loader span:nth-child(2) {
//   filter: blur(10px);
// }

// .loader span:nth-child(3) {
//   filter: blur(25px);
// }

// .loader span:nth-child(4) {
//   filter: blur(50px);
// }

//-------------------------------
.loader {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

.loader::before {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #ff00cc 0%, #333399 100%);
  animation: load012323 .5s infinite linear;
}

.loader::after {
  width: 85%;
  height: 85%;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes load012323 {
  to {
    transform: rotate(360deg);
  }
}