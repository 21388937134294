* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-size: 16px;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol {
  margin: 10px 0 20px 15px;
}

ul {
  margin: 10px 0 20px 20px;
}

li {
  margin: 10px 20px 0 0;
}

h3, h4 {
  margin: 10px 0 10px 0; 
}


#root {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}